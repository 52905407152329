import React, {useEffect, useState} from 'react';
import {
  NumberInput,
  SimpleForm,
  TextInput,
  required,
  DateInput,
  SelectInput,
  Edit,
  NumberField,
  Toolbar,
  SaveButton,
  useEditController,
} from 'react-admin';
import {convertCommaNumber} from '../utils/util';

const COUPON_TYPE = [{ id: "COMMON", name: "COMMON" }];

const DISCOUNT_TYPE = [
  { id: "AMOUNT", name: "AMOUNT" },
  { id: "RATE", name: "RATE" },
];

const STATUS = [
  { id: "PAUSE", name: "PAUSE" },
  { id: "USE", name: "USE" },
];

const CustomToolbar = props => {
  return (
      <Toolbar {...props}>
        <SaveButton/>
      </Toolbar>
  )
}

/**
 * Component for editing a coupon
 * @param props the react admin Create component properties
 * @return {JSX.Element}
 * @constructor
 */
export default function CouponEdit(props) {
  const [minPrice, setMinPrice] = useState();
  const [maxDiscount, setMaxDiscount] = useState();

  // useEditController를 사용하여 현재 편집 중인 레코드의 데이터에 접근
  const { record } = useEditController(props);

  useEffect(() => {
    if (record) {
      setMinPrice(record.min_price);
      setMaxDiscount(record.max_discount);
    }
  }, [record]);


  return (
    <Edit {...props}>
      <SimpleForm toolbar={<CustomToolbar />} >
        <TextInput
          multiline
          source="title"
          label="Title"
          validate={required()}
        />
        <SelectInput
          source="coupon_type"
          choices={COUPON_TYPE}
          validate={required()}
        />
        <SelectInput
          source="discount_type"
          choices={DISCOUNT_TYPE}
          validate={required()}
        />
        <NumberInput
          source="discount_amount"
          label="Discount Amount"
          validate={required()}
        />
        <NumberInput
          source="min_price"
          label="Min Price"
          onChange={(event) => setMinPrice(event.target.value)}
          validate={required()}
        />
        <NumberInput
          source="max_discount"
          label="Max Discount"
          onChange={(event) => setMaxDiscount(event.target.value)}
          validate={required()}
        />

        <div style={{padding: 10}}>
          <p><strong>{convertCommaNumber(minPrice)}원</strong> 이상인 상품에 적용 가능</p>
          <p>최대 <strong>{convertCommaNumber(maxDiscount)}원</strong> 할인</p>
        </div>
        <br/>

        <NumberInput
          source="max_issued_cnt"
          label="Max Issued Cnt"
          validate={required()}
          defaultValue={0}
        />
        <NumberField
          source="issued_cnt"
          label="Issued Cnt"
          validate={required()}
        />
        <DateInput source="start_datetime" validate={required()} />
        <DateInput source="end_datetime" validate={required()} />
        <NumberInput
          source="valid_day_cnt"
          validate={required()}
          defaultValue={14}
        />
        <SelectInput source="status" choices={STATUS} validate={required()} />
      </SimpleForm>
    </Edit>
  );
}
