import React, {useState} from "react";
import {
  Create,
  NumberInput,
  SimpleForm,
  TextInput,
  required,
  DateInput,
  SelectInput,
  ImageField,
  ImageInput,
} from "react-admin";
import {convertCommaNumber} from '../utils/util';

const COUPON_TYPE = [{ id: "COMMON", name: "COMMON" }];

const DISCOUNT_TYPE = [
  { id: "AMOUNT", name: "AMOUNT" },
  { id: "RATE", name: "RATE" },
];

const STATUS = [
  { id: "PAUSE", name: "PAUSE" },
  { id: "USE", name: "USE" },
];

/**
 * Component for creating a coupon
 * @param props the react admin Create component properties
 * @return {JSX.Element}
 * @constructor
 */
export default function CouponCreate(props) {
  const [minPrice, setMinPrice] = useState(0);
  const [maxDiscount, setMaxDiscount] = useState(0);
  return (
    <Create {...props}>
      <SimpleForm redirect="list">
        <TextInput
          multiline
          source="title"
          label="Title"
          validate={required()}
        />
        <SelectInput
          source="coupon_type"
          choices={COUPON_TYPE}
          validate={required()}
        />
        <SelectInput
          source="discount_type"
          choices={DISCOUNT_TYPE}
          validate={required()}
        />
        <NumberInput
          source="discount_amount"
          label="Discount Amount"
          validate={required()}
        />
        <NumberInput
          source="min_price"
          label="Min Price"
          onChange={(event) => setMinPrice(event.target.value)}
          validate={required()}
        />
        <NumberInput
          source="max_discount"
          label="Max Discount"
          onChange={(event) => setMaxDiscount(event.target.value)}
          validate={required()}
        />

        <div style={{padding: 10}}>
          <p><strong>{convertCommaNumber(minPrice)}원</strong> 이상인 상품에 적용 가능</p>
          <p>최대 <strong>{convertCommaNumber(maxDiscount)}원</strong> 할인</p>
        </div>
        <br/>

        <NumberInput
          source="max_issued_cnt"
          label="Max Issued Cnt"
          validate={required()}
          defaultValue={0}
        />
        <DateInput source="start_datetime" validate={required()} />
        <DateInput source="end_datetime" validate={required()} />
        <NumberInput
          source="valid_day_cnt"
          validate={required()}
          defaultValue={14}
        />
        <SelectInput source="status" choices={STATUS} validate={required()} />
        <ImageInput source="image_url" accept="image/*" multiple={false}>
          <ImageField source="url" />
        </ImageInput>
      </SimpleForm>
    </Create>
  );
}
