import React, {
  Fragment,
  useState, useEffect, useCallback
} from 'react';
import {
    AutocompleteInput,
    Button,
    List,
    Datagrid,
    DateInput,
    DateTimeInput,
    FormDataConsumer,
    Labeled,
    Link,
    TextField,
    TextInput,
    Filter,
    Edit,
    SimpleForm,
    DateField,
    ImageInput,
    ImageField,
    ReferenceInput,
    ReferenceField,
    SaveButton,
    Toolbar,
    Create,
    NumberInput,
    FunctionField,
    TopToolbar,
    CreateButton,
    ExportButton,
} from 'react-admin';
import { useForm } from 'react-final-form';
import _ from 'lodash';
import { DateTimePicker } from "../components/material-ui/DatePicker";
import Avatar from '@material-ui/core/Avatar';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Chip from '@material-ui/core/Chip';
import {AddCircle, Check, Delete} from '@material-ui/icons';
import Divider from '@material-ui/core/Divider';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';

import UsernameProfileField from "../components/UsernameProfileField";
import ProductImageField from "../components/ProductImageField";
import UserLinkField from "../components/UserLinkField";
import SimpleProductCard from "../components/SimpleProductCard";
import ResourceSearcher from "../components/react-admin/customs/ResourceSearcher";
import TablePaginationActions from '../components/material-ui/customs/TablePaginationActions';
import useForceUpdate from "../hooks/useForceUpdate";
import {paginate, getResizedURL} from "../utils/util";
import withStyles from "@material-ui/core/styles/withStyles";

import { validateUrl } from '../lib/validator';
import { WEBAPP_URL } from '../config';
import { collectionUrl } from '../lib/url';
import {JsonInput} from "react-admin-json-view";
import {Accordion, AccordionDetails, AccordionSummary} from "@material-ui/core";
import CollectionExampleImage from '../assets/images/collection_example.webp';
import AddImageCollectionModal from '../components/AddImageInCollectionModal';
import ExportImageUrlModal from '../components/ExportImageUrlModal';

const CollectionFilter = (props) => (
    <Filter {...props}>
        <DateInput source="active_date" label="Active Date" alwaysOn />
        <TextInput label="Search" source="search" alwaysOn/>
    </Filter>
)

const CustomToolbar = withStyles({toolbar: { display: 'flex', justifyContent: 'space-between'}})(props => (
    <Toolbar {...props}>
        <SaveButton undoable={false}/>
    </Toolbar>
));

const styles = {
  theme: {}, // for pagination prop
  table: {},
  imagePreview: {
      '& img': {
          maxWidht: '270px',
          maxHeight: '90px'
      }
  },
  urlFieldCell: {
      maxWidth: 140
  },
  urlField: {
      display: 'inline-block',
      textOverflow: "ellipsis",
      overflow: "hidden",
      width: "100%"
  },
  CollectionProductFrame: {
    width: '100%'
  }
};

export const CollectionList = withStyles(styles)(({classes, ...props}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isImageModalOpen, setIsImageModalOpen] = useState(false);

    return (
        <Fragment>
        <List {...props} filters={<CollectionFilter/>} sort={{field: 'featured_until', order: 'DESC'}}
              actions={
                  <TopToolbar>
                      <CreateButton basePath={props.basePath}/>
                      <ExportButton props/>
                      <Button label="Collection 이미지 수정" style={{color: 'green'}} onClick={() => setIsModalOpen(true)}></Button>
                      <Button label="Public Image 추출" style={{color: 'red'}} onClick={() => setIsImageModalOpen(true)}></Button>
                  </TopToolbar>
              }
        >
            <Datagrid rowClick="edit">
                <TextField source="id"/>
                <DateField source="featured_from"/>
                <DateField source="featured_until"/>
                <ImageField source="resizedSmallImage.url" className={classes.imagePreview}/>
                <TextField source="title"/>
                <ReferenceField label="Seller" source="seller_id" reference="users" link="show" allowEmpty>
                    <UserLinkField/>
                </ReferenceField>
            </Datagrid>
        </List>
            <AddImageCollectionModal open={isModalOpen} onClose={() => setIsModalOpen(false)}/>
            <ExportImageUrlModal open={isImageModalOpen} onClose={() => setIsImageModalOpen(false)}/>
        </Fragment>
    )
})

const actionsStyles = theme => ({
  root: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing.unit * 2.5,
  },
});
const TablePaginationActionsWrapped = withStyles(actionsStyles, { withTheme: true })(
  TablePaginationActions,
);

const collectionProductsSortMethod = (a, b) => {
   /* Implements order: [[db.sequelize.fn('COALESCE', db.sequelize.col('collection_product.featuredAt'), db.sequelize.col('collection_product.createdAt')), 'DESC'],['product_id', 'DESC']] */
  const aFeaturedAtOrCreatedAt = a.collection_product?.featuredAt || a.collection_product?.createdAt;
  const bFeaturedAtOrCreatedAt = b.collection_product?.featuredAt || b.collection_product?.createdAt;

  const aFeaturedDate = aFeaturedAtOrCreatedAt ? new Date(aFeaturedAtOrCreatedAt) : null;
  const bFeaturedDate = bFeaturedAtOrCreatedAt ? new Date(bFeaturedAtOrCreatedAt) : null;

  if(aFeaturedDate == bFeaturedDate) {
    return a.id < b.id ? 1 : -1; // product.id, DESC
  } else if (!aFeaturedDate) {
    return -1;
  } else if (!bFeaturedDate) {
    return 1;
  } else {
    return aFeaturedDate < bFeaturedDate ? 1 : -1;
  }
}
export const CollectionProductList = withStyles(styles, { withTheme: true })(({classes = {}, theme, products, onDeleteProduct, ...props}) => {
  const [updateCounter, setUpdateCounter] = useState(0); // for force update
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [displayProducts, setDisplayProducts] = useState(products && paginate(products, page, rowsPerPage) || []); // paginated Products

  const now = new Date();

  const onDelete = useCallback((product) => {
    onDeleteProduct(product)
  }, [onDeleteProduct]);

  const onChangePage = (event, newPage) => {
    setPage(newPage);
  }
  const onChangeRowsPerPage = (event) => {
    setPage(page);
    setRowsPerPage(event.target.value);
  };
  useEffect(() => {
    /* To update displayProductIds */
    const productsCopy = [...products];
    productsCopy.sort(collectionProductsSortMethod);
    const newDisplayProducts = paginate(productsCopy, page, rowsPerPage);
    setDisplayProducts(newDisplayProducts);
  }, [updateCounter, page, rowsPerPage, products]);
  return (
    <div style={{marginBottom: '80px'}}>
      { displayProducts.length ?
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>id</TableCell>
              <TableCell>image</TableCell>
              <TableCell>title</TableCell>
              <TableCell>status</TableCell>
              <TableCell>seller</TableCell>
              <TableCell>Added at</TableCell>
              <TableCell>featured at</TableCell>
              <TableCell>Delete</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {displayProducts.map((product, index)=> {
              return (
                <TableRow key={`${product.id}_${index}`}>
                  <TableCell>{product.id}</TableCell>
                  <TableCell><ProductImageField record={product}/></TableCell>
                  <TableCell>
                    <Link to={`/products/${product.id}`} target="_blank" rel="noopener noreferrer">
                      {product.title}
                    </Link>
                  </TableCell>
                  <TableCell>{product.status}</TableCell>
                  <TableCell align="right"><UsernameProfileField record={product.seller.user}/></TableCell>
                  <TableCell><DateField source="collection_product.createdAt" record={product}/></TableCell>
                  <TableCell>
                    <DateTimePicker value={product.collection_product?.featuredAt || null} onChange={(newDate) => {
                      product.collection_product = Object.assign({}, product.collection_product, {featuredAt: newDate});
                      setUpdateCounter(updateCounter + 1);
                      }} ampm={false} format='yyyy.MM.dd HH:mm:ss' maxDate={now} clearable />
                  </TableCell>
                  <TableCell align="right">
                    <Button onClick={() => {onDelete(product)}}><Delete /></Button>
                  </TableCell>
                </TableRow>
              )}
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[1, 2, 5, 10, 25]}
                component="div"
                count={products.length || 0}
                rowsPerPage={rowsPerPage}
                page={page}
                backIconButtonProps={{
                  'aria-label': 'Previous Page',
                }}
                nextIconButtonProps={{
                  'aria-label': 'Next Page',
                }}
                onChangePage={onChangePage}
                onChangeRowsPerPage={onChangeRowsPerPage}
                ActionsComponent={TablePaginationActionsWrapped}
              />
            </TableRow>
          </TableFooter>
        </Table>
        : null
      }
    </div>
  );
});


const collectionProductModalStyles = {
  dialogContent: {
    textAlign: 'center'
  },
  dialogPaperWidthMd: {
    minWidth: '75%',
    maxWidth: '75%',
    height: '100%'
  },
  extractButton: {
    margin: '10px'
  },
  searchedProductDiv: {
    display: 'flex',
    justifyContent: 'center',
    flexFlow: 'row wrap'
  },
  productCard: {
    width: 240,
    margin: '0.2rem'
  },
  productChip: {
    padding: '1px',
    margin: '0.1rem'
  }
};

const CollectionProductAdderModal = withStyles(collectionProductModalStyles)(({classes, basePath, open, onClose, onAdd, ...props}) => {
  const [searchedProducts, setSearchedProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState(new Map());
  const now = new Date();
  const onSelectItem = useCallback((product) => {
    selectedProducts.set(product.id, product);
    setSelectedProducts(new Map(selectedProducts));
  });

  const onAddNewProducts = useCallback(() => {
    const selectedProductArray = [...selectedProducts.values()];
    onAdd(selectedProductArray);
    onClose();
    setSelectedProducts(new Map());
  });

  const ProductCardAction = ({product, isSelected}) => {
    const FeaturedAtPicker = () => {
      return (
        <DateTimePicker value={product.collection_product?.featuredAt || null} onChange={(newDate) => {
          product.collection_product = Object.assign({}, product.collection_product, {featuredAt: newDate});
          setSearchedProducts([...searchedProducts]);
          }} label="Featured at" ampm={false} format='yyyy.MM.dd HH:mm:ss' maxDate={now} clearable />
      )
    };

    return (
      <div style={{display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center'}}>
        <FeaturedAtPicker />
        {
          isSelected
            ? <Check>Added</Check>
            : <Button onClick={e => onSelectItem(product)} label="Add"><AddCircle /></Button>
        }
      </div>
    );
  }
  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" classes={{paperWidthMd: classes.dialogPaperWidthMd}}>
        <DialogTitle>Collection Products Adder</DialogTitle>
        <DialogContent classes={{root: classes.dialogContent}}>
          <ResourceSearcher resource="products" filterKeys={['title']} placeholder="Enter the title" onChangeSearchedList={(products) => setSearchedProducts(products)}/>
          <div style={{marginTop: '20px'}}>
            {
              [...selectedProducts.keys()].map((key) => {
                const myProduct = selectedProducts.get(key);
                return <Chip className={classes.productChip} color="primary" key={key} label={`${key}: ${myProduct.title}`}
                  avatar={<Avatar alt={myProduct.id} src={myProduct.resizedSmallImages[0].url}/>}
                  onDelete={() => {selectedProducts.delete(key); setSelectedProducts(new Map(selectedProducts));}} />
              })
            }
          </div>
          <Divider style={{margin: '18px'}}/>
          <div className={classes.searchedProductDiv}>
            {
              searchedProducts.map(product => {
                const selectedProduct = selectedProducts.get(product.id);
                return <SimpleProductCard key={product.id} className={classes.productCard}
                  product={selectedProduct || product} actions={<ProductCardAction product={selectedProduct || product} isSelected={!!selectedProduct} />}
                />
              })
            }
          </div>
        </DialogContent>
        <DialogActions>
          <Button label="Cancel" onClick={onClose} />
          <Button label="Add" onClick={onAddNewProducts} />
        </DialogActions>
    </Dialog>
  )
})

//Json 형태 데이터 가이드
const CollectionJsonStructureExample = {
    image: {url : '', caption: '', aspectRatio: 1, },
    description: '',
    list: {
        title: '',
        subtitle: '',
        collection: {id: '1'},
        filter: {status: 'selling'}
    }
};
const CollectionJsonDataExample = () => {
    return <div>
        <Accordion>
            <AccordionSummary>
                <div>extra_info 데이터 guide</div>
            </AccordionSummary>
            <AccordionDetails>
                 <pre>
                     <code>
                        {`
                            [
                                {
                                    image {
                                      url : '' (필수)
                                      caption: '', (선택)
                                      aspectRatio: '' (필수 - 기본 값 1)
                                    },
                                    description: '',
                                    list: {
                                        title: '',
                                        subtitle: ''
                                        collection: {
                                            id : ''
                                        },
                                        filter:  {
                                            status: 'selling'
                                        }
                                    }
                                }
                            ]
                         `
                        }
                    </code>
                 </pre>
                <div>
                    image : url - 이미지 URL<br/>
                    image : caption - 이미지 캡션 <br/>
                    image : aspectRatio - 이미지 비율 캡션 <br/>
                    description : 설명 문구<br/>
                    list : title - 리스트 타이틀<br/>
                    list : subtitle - 리스트 하위타이틀<br/>
                    list : collection id - 컬렉션 id
                </div>
            </AccordionDetails>
        </Accordion>
    </div>
}

export const CollectionEdit = withStyles(styles)(({classes = {}, ...props}) => {
    const [isOpenProductAdderModal, setIsOpenProductAdderModal] = useState(false);
    const forceUpdate = useForceUpdate();

    return (
        <Edit {...props} undoable={false} transform={(data) => {
            const transformProducts = data?.myProduct?.map(product =>
                ({
                    id: product.id,
                    collection_product: product.collection_product,
                    seller: {user: product.seller.user},
                    images: [product.images[0]],
                    resizedSmallImages: [product.resizedSmallImages[0]],
                })
            );
            return {
                ...data,
                myProduct: transformProducts && [...transformProducts],
            }
        }}
        >
            <SimpleForm toolbar={<CustomToolbar/>}>
                <TextInput source="id" disabled/>
                <TextInput source="title" label="Title"/>
                <DateTimeInput source="featured_from" label="featured from"/>
                <DateTimeInput source="featured_until" label="featured until"/>
                <NumberInput label="Priority" source="priority"/>
                <TextInput source="subtitle" label="Subtitle"/>
                <TextInput source="sub_header" label="SubHeader"/>
                <TextInput multiline label="Description" source="description"/>
                <FormDataConsumer>
                    {({formData}) => {
                      const form = useForm();
                      if (formData.extra_info === null) {
                          form.change('extra_info', []);
                      }
                      return (
                          <>
                              <JsonInput
                                  source="extra_info"
                                  reactJsonOptions={{
                                      name: false,
                                      displayArrayKey: true,
                                      displayDataTypes: false,
                                      displayObjectSize: false,
                                      enableClipboard: false,
                                      quotesOnKeys: false
                                  }}
                                  helperText="⚠️ 잘못된 값은 앱을 충돌시킬 수 있습니다."
                              />
                              <Button label="extra_info 데이터 추가" onClick={() => {
                                  const newExtraInfo = [...formData.extra_info];
                                  newExtraInfo.push(CollectionJsonStructureExample);
                                  form.change('extra_info', newExtraInfo);
                              }}/>
                          </>
                      )
                    }}
                </FormDataConsumer>
                <CollectionJsonDataExample />
      <ImageInput source="image" accept="image/*" multiple={false} format={image => {
        if(image && image.url) {
          const url = image.url;
          const isBlobUrl = typeof url === 'string' && url.match(/^blob:/);
          return isBlobUrl ? {url} : {url: getResizedURL(url, 'width620')};
        } else {
          return image;
        }
      }}>
        <ImageField source="url" title="title" />
      </ImageInput>
      <TextInput source="image_caption" label="이미지 캡션" />
      <TextInput source="aspect_ratio" label="이미지 비율" defaultValue={1} />
      <TextInput label="link" source="link" resettable style={{width:'100%'}}
        validate={[validateUrl({
          allowEmpty: true,
          require_valid_protocol: false,
          require_host: false,
          require_tld: false
          }, 'Invalid URL format (Required schema)')]} />
      <ReferenceField label="Seller" source="seller_id" reference="users" link="show" allowEmpty>
        <UserLinkField/>
      </ReferenceField>

      {/* Collection Products */}
      <Labeled label="Collection products" />
      <Button variant="contained" label="Add product" onClick={() => setIsOpenProductAdderModal(true)} />
      <FormDataConsumer>
        {({formData}) => {
          const form = useForm();
          console.log('form data', formData);
          return (<CollectionProductAdderModal open={isOpenProductAdderModal} onAdd={(newAddedProducts) => {
              const newMyProduct = [...newAddedProducts];
              newMyProduct.push(...formData.myProduct);
              const nonDuplicatedProducts = _.uniqBy(newMyProduct, 'id');
              form.change('myProduct', nonDuplicatedProducts);
              forceUpdate();
            }}
            onClose={() => setIsOpenProductAdderModal(false)}
          />)
        }}
      </FormDataConsumer>
      <FormDataConsumer>
        {({formData}) => {
          const form = useForm();
          const onDeleteProduct = (toBeDeletedProduct) => {
            const exceptedMe = formData.myProduct.filter((product) => product.id !== toBeDeletedProduct.id);
            form.change('myProduct', exceptedMe);
          }
          return (
            <CollectionProductList className={classes.CollectionProductFrame} products={formData.myProduct || []} onDeleteProduct={onDeleteProduct}/>
          )
        }}
      </FormDataConsumer>

      {/* Webapp and content feed links */}
      <FunctionField
          label="Deep Link"
          render={record => (
              <a href={collectionUrl(WEBAPP_URL, record)} target="_blank">
                  {collectionUrl(WEBAPP_URL, record).toString()}
              </a>
          )}
      />
      <FunctionField
          label="Atom Feed"
          render={record => (
              <a href={collectionUrl(WEBAPP_URL, record) + '/feed.atom'} target="_blank">
                  {collectionUrl(WEBAPP_URL, record) + '/feed.atom'}
              </a>
          )}
      />
      <FunctionField
          label="Google Shopping Feed"
          render={record => (
              <a href={collectionUrl(WEBAPP_URL, record) + '/googleshopping.xml'} target="_blank">
                  {collectionUrl(WEBAPP_URL, record) + '/googleshopping.xml'}
              </a>
          )}
      />
      <img style={{
          position: 'absolute',
          right: 40,
          top: 100,
          width: '360px'}} src={CollectionExampleImage} alt={'collection_example'} />
    </SimpleForm>
  </Edit>
  )
});

export const CollectionCreate =  withStyles(styles)(({classes = {}, ...props}) => {
  const [isOpenProductAdderModal, setIsOpenProductAdderModal] = useState(false);
  const forceUpdate = useForceUpdate();

  return (
  <Create {...props} transform={(data) => {
      const transformProducts = data?.myProduct?.map(product =>
          ({
              id: product.id,
              collection_product: product.collection_product,
              seller: {user: product.seller.user},
              images: [product.images[0]],
              resizedSmallImages: [product.resizedSmallImages[0]],
          })
      );
      return {
          ...data,
          myProduct: transformProducts && [...transformProducts],
      }
  }}>
    <SimpleForm redirect="list">
      <TextInput source="title" label="Title" />
      <DateTimeInput source="featured_from" label="featured from" />
      <DateTimeInput source="featured_until" label="featured until" />
      <NumberInput label="Priority" source="priority" />
      <TextInput source="subtitle" label="Subtitle" />
      <TextInput source="sub_header" label="SubHeader" />
      <TextInput multiline source="description" label="Description" />
      <JsonInput
          source="extra_info"
          defaultValue={[]}
          reactJsonOptions={{
            name: false,
            displayArrayKey: true,
            displayDataTypes: false,
            displayObjectSize: false,
            enableClipboard: false,
            quotesOnKeys: false
          }}
          helperText="⚠️ 잘못된 값은 앱을 충돌시킬 수 있습니다."
      />
      <FormDataConsumer>
            {({formData}) => {
                const form = useForm();
                return (<Button label="extra_info 데이터 추가" onClick={() => {
                    if (formData.extra_info !== null) {
                        const newExtraInfo = [...formData.extra_info];
                        newExtraInfo.push(CollectionJsonStructureExample);
                        form.change('extra_info', newExtraInfo);
                    }
                }}/>)
            }}
      </FormDataConsumer>
      <CollectionJsonDataExample />
      <ImageInput source="image" accept="image/*" multiple={false}>
          <ImageField source="url" title="title"/>
      </ImageInput>
      <TextInput source="image_caption" label="이미지 캡션" />
      <TextInput source="aspect_ratio" label="이미지 비율" defaultValue={1} />
      <TextInput label="link" source="link" resettable style={{width:'100%'}}
        validate={[validateUrl({
          allowEmpty: true,
          require_valid_protocol: false,
          require_host: false,
          require_tld: false
          }, 'Invalid URL format (Required schema)')]}  />
      <ReferenceInput label="User" source="user_id" reference="users" allowEmpty>
          <AutocompleteInput source="user_id" optionText={choice => `${choice?.username}`} allowEmpty />
      </ReferenceInput>

      {/* Collection Products */}
      <Labeled label="Collection products" />
      <Button variant="contained" label="Add product" onClick={() => setIsOpenProductAdderModal(true)} />
      <FormDataConsumer>
        {({formData}) => {
          const form = useForm();
          console.log('form data', formData);
          return (<CollectionProductAdderModal open={isOpenProductAdderModal} onAdd={(newAddedProducts) => {
              const newMyProduct = [...newAddedProducts];
              formData.myProduct && newMyProduct.push(...formData.myProduct);
              const nonDuplicatedProducts = _.uniqBy(newMyProduct, 'id');
              form.change('myProduct', nonDuplicatedProducts);
              forceUpdate();
            }}
            onClose={() => setIsOpenProductAdderModal(false)}
          />)
        }}
      </FormDataConsumer>
      <FormDataConsumer>
        {({formData}) => {
          const onDeleteProduct = (toBeDeletedProduct) => {
            const exceptedMe = formData.myProduct.filter((product) => product.id !== toBeDeletedProduct.id);
            form.change('myProduct', exceptedMe);
          }
          return (
            <CollectionProductList className={classes.CollectionProductFrame} products={formData.myProduct || []} onDeleteProduct={onDeleteProduct}/>
          )
        }}
      </FormDataConsumer>
      <img style={{
          position: 'absolute',
          right: 40,
          top: 100,
          width: '360px'}} src={CollectionExampleImage} alt={'collection_example'} />
    </SimpleForm>
  </Create>
  )
})
